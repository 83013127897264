import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { reset } from "../../pages/user/UserSlice";

export const routeNames: { path: string; name: string }[] = [
  {
    path: "/daily",
    name: "Today's Data",
  },
  {
    path: "/hourly",
    name: "Hourly Historic Data",
  },
  // {
  //   path: "/history",
  //   name: "Historic Data",
  // },
];

export const Header: React.FC<{}> = () => {
  const { state, username } = useAppSelector(s => s.user)
  const location = useLocation();
  const dispatch = useAppDispatch();
  return (
    <div className="flex w-full flex-col md:flex-row justify-between items-center pt-4">
      <div className="flex flex-row justify-start items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="170"
          height="34"
          className="max-w-screen-sm mx-4 bg-transparent rounded-md"
          viewBox="0 0 150 30"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#1E3246"
              d="M14.582 17.154c-1.774-.227-4.37.054-5.944.74L12.484 7.88l7.46 17.952h5.695L15.806 2.186H9.122L0 25.844h5.69c1.034-3.52 5.56-6.168 9.762-6.543l-.87-2.147zM45.517 22.267v4.63H28.966V3.103h5.841v19.164zM90 3.103v4.623h-7.856v19.171H76.12V7.726h-7.844V3.103zM149.985 2.215v23.617h-5.918V11.418l-5.543 7.637h-.85l-5.541-7.637v14.414h-5.916V2.215h5.19l6.679 8.989 6.766-8.989z"
            ></path>
            <path
              fill="#D1002D"
              d="M120.47 21.724c2.03-4.11 1.724-9.03-.47-12.871l1.367-1.612c2.326 4.376 2.357 9.88-.16 14.453l-.738.03zm-21.642-5.113c.03-4.276 3.517-7.8 7.904-7.908 4.481-.125 8.198 3.343 8.306 7.742.116 4.404-3.413 8.055-7.894 8.171a8.182 8.182 0 0 1-5.78-2.168l-1.171 1.204A12.514 12.514 0 0 1 99.72 5.72c3.946-4.084 10.004-5.015 14.902-2.736l.065.067.624-.702c-6.235-3.647-14.46-2.61-19.715 2.813-6.194 6.401-5.9 16.503.618 22.574l1.174-1.208c2.576 2.383 5.578 3.557 9.078 3.467 7.044-.186 12.604-5.931 12.428-12.848a12.352 12.352 0 0 0-3.965-8.755l.825-.87c-4.098-3.813-10.881-3.73-14.832.358-2.35 2.428-3.458 5.699-2.93 8.761l.835-.03z"
            ></path>
            <path
              fill="#1E3246"
              d="M65.415 8.097c-6.856-2.457-11.271-.77-11.271 1.16 0 4.412 13.115 1.905 13.097 10.501-.019 6.435-5.424 8.173-10.958 8.173-2.315 0-5.202-.355-7.415-1.066l.572-5.167c1.66.792 4.104 1.33 6.843 1.33 2.398 0 5.244-.469 5.391-2.356.387-4.9-13.053-1.83-13.053-10.793 0-5.968 5.681-7.81 10.679-7.81 2.342 0 5.436.8 6.51 1.283l-.395 4.745z"
            ></path>
          </g>
        </svg>
      </div>
      <div className="p-5 text-4xl text-gray-600 text-opacity-70 font-semibold font-sans">
        Air Quality Monitoring Platform - Alstom Transport India Ltd
      </div>
      <div className="flex flex-row justify-end items-center px-4 gap-2">
        {routeNames.map((r) => (
          <Link
            to={r.path}
            className={`${
              location.pathname === r.path
                ? "bg-gray-500 text-gray-100"
                : "bg-transparent text-gray-600"
            } border-2 p-3 rounded-md shadow-md border-gray-500  font-semibold font-sans text-lg text-center`}
          >
            {r.name}
          </Link>
        ))}
        {state === "loggedIn" ? (
          <>
            <div className="text-gray-600 p-2 font-sans text-sm border-b-4 border-gray-600">
              {username}
            </div>
            <Link
              to="/signin"
              onClick={(e) => {
                dispatch(reset());
              }}
              className={`text-gray-600 border-2 border-gray-600 rounded-full p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
                location.pathname === "/signin"
                  ? "border-b-8"
                  : "text-gray-600 bg-transparent"
              }`}
            >
              Logout
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Header;